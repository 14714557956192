import { ScopedPermissions } from '@air/api';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useWorkspacePermissions } from '~/hooks/useWorkspacePermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface CurrentWorkspacePermissionsProviderProps extends PropsWithChildren<{}> {}

export interface CurrentWorkspacePermissionsProviderContextValue {
  data: ScopedPermissions | undefined;
  isFetched: boolean;
}

const defaultValue: CurrentWorkspacePermissionsProviderContextValue = {
  data: undefined,
  isFetched: false,
};

const CurrentWorkspacePermissionsProviderContext =
  createContext<CurrentWorkspacePermissionsProviderContextValue>(defaultValue);

export const CurrentWorkspacePermissionsProvider = ({ children }: CurrentWorkspacePermissionsProviderProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const currentWorkspaceId = currentWorkspace?.id;
  const { isFetched } = useFetchObjectsPermissions({
    objects: {
      workspaceId: currentWorkspaceId,
    },
  });
  const { workspacePermissions } = useWorkspacePermissions({ workspaceId: currentWorkspaceId });

  const data = useMemo(() => ({ data: workspacePermissions, isFetched }), [isFetched, workspacePermissions]);

  return (
    <CurrentWorkspacePermissionsProviderContext.Provider value={data}>
      {children}
    </CurrentWorkspacePermissionsProviderContext.Provider>
  );
};

export const useCurrentWorkspacePermissionsContext = () => {
  const context = useContext(CurrentWorkspacePermissionsProviderContext);

  if (context === defaultValue) {
    const error = 'CurrentWorkspacePermissionsProviderContext used outside of CurrentWorkspacePermissionsProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
