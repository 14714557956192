import { ClipsListResponse } from '@air/api';
import {
  Account,
  Asset,
  Board,
  Clip,
  ClipAndBoardListItem,
  ClipsListOptions,
  CustomField,
  ListResponse,
  Tag,
} from '@air/api/types';
import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { constant, identity, intersection } from 'lodash';
import { useCallback } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import { GALLERY_ASSETS, GALLERY_FILES, GALLERY_MIXED_DATA } from '~/constants/react-query-keys';
import { Routes } from '~/constants/routes';
import { FilterParamNames } from '~/constants/search';
import { useFiltersContext } from '~/providers/FiltersProvider';
import { pathnameSelector } from '~/store/router/selectors';
import { getAccountKey } from '~/swr-hooks/account/useAccount';
import { defaultAssetsData, defaultTableViewData } from '~/swr-hooks/gallery/types';
import { QUERY_CLIENT_CACHE_TIME, QueryInfiniteData } from '~/swr-hooks/queryClient';
import { getAssetGalleryItemType, isAssetFileType } from '~/utils/AssetUtils';
import { customFieldIdFromUrlParam } from '~/utils/filters/urlParamsToFiltersUtils';
import { useUpdateItemsCount } from '~/utils/mutateUtils/BoardStats';
import {
  getGalleryDataWithoutIds,
  getTableDataWithoutIds,
  standardNewVersionOverride,
  updateVersionInTableData,
} from '~/utils/mutateUtils/mutators';
import { useUpdateSearchTotals } from '~/utils/mutateUtils/SearchTotals';
import { useAddAssetVersion } from '~/utils/mutateUtils/useAddAssetVersion';
import {
  assetIdsExistsInGalleryView,
  assetIdsExistsInTableView,
  clipIdsExistsInGalleryView,
  clipIdsExistsInTableView,
} from '~/utils/mutateUtils/utils';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';
import { isClipTableItem } from '~/utils/tableViewDataUtils';

import { useUpdateKanbanData } from './useUpdateKanbanData';

export type QueryKeyParamsType = (DeepPartial<ClipsListOptions> & { basePathName?: string }) | undefined;

const getQueryKey = ({
  mainKey,
  parentBoardId,
  queryKeyParams = {},
}: {
  parentBoardId?: Board['id'];
  mainKey: string;
  queryKeyParams?: QueryKeyParamsType;
}): QueryFilters => ({
  queryKey: [mainKey, queryKeyParams],
  predicate: ({ queryKey }) => {
    if (parentBoardId) {
      if (queryKey[0] !== mainKey) {
        return false;
      }
      const key = queryKey[1] as DeepPartial<ClipsListOptions>;
      return key.filters?.board?.is === parentBoardId || key.filters?.boardTree?.is === parentBoardId;
    }
    return true;
  },
});

function getNewQueryData<T>(updateFn: (data: T[]) => T[] | undefined, defaultData: T[]) {
  return (data?: QueryInfiniteData<T>): QueryInfiniteData<T> => {
    return data
      ? {
          pages: updateFn(data.pages) || [],
          pageParams: data?.pageParams,
        }
      : {
          pages: updateFn(defaultData) || [],
          pageParams: [],
        };
  };
}

/**
 * These functions will update any assets in all of the views (gallery, table, etc.)
 */
const useMutateAssetsInAllViews = () => {
  const queryClient = useQueryClient();

  const mutateGalleryData = useCallback(
    ({
      parentBoardId,
      updateFn,
      types = [GalleryItemType.asset, GalleryItemType.file],
      queryKeyParams = {},
      shouldUpdate,
    }: {
      parentBoardId?: Board['id'];
      updateFn: (data: ClipsListResponse<Clip>[]) => ClipsListResponse<Clip>[] | undefined;
      types?: GalleryItemType[];
      queryKeyParams?: QueryKeyParamsType;
      shouldUpdate: (data?: QueryInfiniteData<ClipsListResponse<Clip>>) => boolean;
    }) => {
      const hasAssets = types.includes(GalleryItemType.asset);
      const hasFiles = types.includes(GalleryItemType.file);

      const queryKey = (mainKey: string) => getQueryKey({ mainKey, parentBoardId, queryKeyParams });

      if (parentBoardId) {
        if (!queryKeyParams.filters) {
          queryKeyParams.filters = {};
        }
        queryKeyParams.filters.board = { is: parentBoardId };
      }

      const assetUploads = [];
      /**
       * It is possible that setQueriesData will be called just before the data is fetched (e.g. on websocket event)
       * If data is mutated by setQueriesData, it is considered as fresh, so fetcher for that cache key won't be called for the next 3 seconds
       * To avoid that, we need to set updatedAt to some time in the past (queryClient stale time - 500ms buffer).
       */
      const dataStaleTime = Date.now() - QUERY_CLIENT_CACHE_TIME - 500;

      if (hasAssets) {
        assetUploads.push(
          queryClient.setQueriesData<QueryInfiniteData<ClipsListResponse<Clip>>>(
            queryKey(GALLERY_ASSETS),
            (data) => {
              return shouldUpdate(data) ? getNewQueryData(updateFn, [defaultAssetsData])(data) : undefined;
            },
            {
              updatedAt: dataStaleTime,
            },
          ),
        );
      }
      if (hasFiles) {
        assetUploads.push(
          queryClient.setQueriesData<QueryInfiniteData<ClipsListResponse<Clip>>>(
            queryKey(GALLERY_FILES),
            (data) => {
              return shouldUpdate(data) ? getNewQueryData(updateFn, [defaultAssetsData])(data) : undefined;
            },
            {
              updatedAt: dataStaleTime,
            },
          ),
        );
      }

      if (assetUploads.length > 0) {
        Promise.all(assetUploads);
        return;
      }
    },
    [queryClient],
  );

  /**
   * Use this method to mutate cache of table data in SWR cache
   * @param parentBoardId id of a board for which you want to update data. If empty, will go through all existing mixed keys
   * @param updateFn function which returns updated data
   */
  const mutateTableData = useCallback(
    ({
      parentBoardId,
      queryKeyParams,
      updateFn,
      shouldUpdate,
    }: {
      parentBoardId?: Board['id'];
      updateFn: (data: ListResponse<ClipAndBoardListItem>[]) => ListResponse<ClipAndBoardListItem>[] | undefined;
      queryKeyParams?: QueryKeyParamsType;
      shouldUpdate: (data?: QueryInfiniteData<ListResponse<ClipAndBoardListItem>>) => boolean;
    }) => {
      const key = getQueryKey({ mainKey: GALLERY_MIXED_DATA, queryKeyParams, parentBoardId });

      /**
       * It is possible that setQueriesData will be called just before the data is fetched (e.g. on websocket event)
       * If data is mutated by setQueriesData, it is considered as fresh, so fetcher for that cache key won't be called for the next 3 seconds
       * To avoid that, we need to set updatedAt to some time in the past (queryClient stale time - 500ms buffer).
       */
      const dataStaleTime = Date.now() - QUERY_CLIENT_CACHE_TIME - 500;

      queryClient.setQueriesData<QueryInfiniteData<ListResponse<ClipAndBoardListItem>>>(
        key,
        (data) => {
          return shouldUpdate(data) ? getNewQueryData(updateFn, [defaultTableViewData])(data) : undefined;
        },
        {
          updatedAt: dataStaleTime,
        },
      );
    },
    [queryClient],
  );

  return {
    mutateGalleryData,
    mutateTableData,
  };
};

/**
 * Use this hook to add assets to all views (gallery, table etc)
 */
export const useAddAssetsToAllViews = () => {
  const store = useAirStore();
  const { mutateTableData, mutateGalleryData } = useMutateAssetsInAllViews();
  const { addItems } = useUpdateItemsCount();
  const { addItemsToKanbanColumn } = useUpdateKanbanData();
  /**
   * Use this method to add asset to gallery cache
   */
  const addAssetsToAllViews = useCallback(
    ({
      parentBoardId,
      assets,
      type,
    }: {
      parentBoardId?: Board['id'];
      assets: Clip[];
      type: GalleryItemType.asset | GalleryItemType.file;
    }) => {
      const currentBoardId = getBoardIdFromPath(window.location.pathname);
      if (parentBoardId && parentBoardId === currentBoardId) {
        addItemsToKanbanColumn({ clips: assets });
        // assets are added to board stats when their status is 'created'
        // since we add this method immediately after upload, revalidating can not include newly uploaded assets
        addItems(parentBoardId, assets, type, false);
      }

      const addAssetToGallery = (data: ClipsListResponse<Clip>[]): ClipsListResponse<Clip>[] => {
        const newData = [...data];
        const firstData = data[0];
        newData[0] = {
          ...firstData,
          data: {
            ...firstData.data,
            clips: [...assets, ...firstData.data.clips],
            total: firstData.data.total + assets.length,
          },
        };
        return newData;
      };

      const addAssetsToTableView = (
        data: ListResponse<ClipAndBoardListItem>[],
      ): ListResponse<ClipAndBoardListItem>[] => {
        const newData = [...data];
        const firstData = data[0];
        newData[0] = {
          ...firstData,
          data: [
            ...assets.map(
              (asset) =>
                ({
                  id: asset.id,
                  type: 'asset',
                  data: asset,
                }) as ClipAndBoardListItem,
            ),
            ...firstData.data,
          ],
          total: firstData.total + assets.length,
        };
        return newData;
      };

      let queryKeyParams: QueryKeyParamsType = {};
      if (!parentBoardId) {
        const { myUploads, recentlyAdded, unusedItems, all } = Routes.media;
        const currentPath = pathnameSelector(store.getState());
        const pathname = [myUploads, recentlyAdded, unusedItems].includes(currentPath) ? currentPath : all;
        // if parentBoardId is not passed, add asset to All assets & files
        queryKeyParams = { basePathName: pathname.split('/')[1] };
      }

      return Promise.all([
        mutateGalleryData({
          parentBoardId,
          types: [type],
          updateFn: addAssetToGallery,
          queryKeyParams,
          shouldUpdate: constant(true),
        }),
        mutateTableData({
          parentBoardId,
          updateFn: addAssetsToTableView,
          queryKeyParams,
          shouldUpdate: constant(true),
        }),
      ]);
    },
    [addItemsToKanbanColumn, addItems, mutateGalleryData, mutateTableData, store],
  );

  return {
    addAssetsToAllViews,
  };
};

/**
 * Use this method to update asset version in gallery. It will update the asset which has the same assetId.
 * if you have information about the old version, use updateAssetVersionInGallery method - it's more performant
 */
export const useUpdateVersionByAssetId = () => {
  const { mutateTableData, mutateGalleryData } = useMutateAssetsInAllViews();
  const { addAssetsToAllViews } = useAddAssetsToAllViews();
  const { updateKanbanData } = useUpdateKanbanData();
  const { addAssetVersion } = useAddAssetVersion();

  const updateVersionByAssetId = useCallback(
    async (newVersion: Clip, parentBoardId?: Board['id']) => {
      const isNewFileType = isAssetFileType(newVersion);
      let shouldRemoveOriginalVersion = false;

      addAssetVersion(newVersion);

      await Promise.all([
        updateKanbanData({
          assetIds: [newVersion.assetId],
          updateClipFn: (asset) => standardNewVersionOverride(asset, newVersion),
        }),
        mutateGalleryData({
          parentBoardId,
          shouldUpdate: (data) => {
            const isAssetInGallery = data?.pages.some((item) =>
              item.data.clips.some((clip) => clip.assetId === newVersion.assetId),
            );
            return !!isAssetInGallery;
          },
          updateFn: (data) =>
            data?.reduce((acc, curr) => {
              const newData = curr.data.clips.reduce((accClips, currClip) => {
                if (newVersion.assetId === currClip.assetId) {
                  // if asset is found in the same list - both are clips or files, replace
                  if (isAssetFileType(newVersion) === isNewFileType) {
                    accClips.push(standardNewVersionOverride(currClip, newVersion));
                  } else {
                    // if not - remove current version, and add new version after this mutate
                    shouldRemoveOriginalVersion = true;
                  }
                } else {
                  accClips.push(currClip);
                }
                return accClips;
              }, [] as Clip[]);
              acc.push({
                ...curr,
                data: {
                  ...curr.data,
                  clips: newData,
                },
              });
              return acc;
            }, [] as ClipsListResponse<Clip>[]),
          types: [getAssetGalleryItemType(newVersion)],
        }),
        mutateTableData({
          parentBoardId,
          shouldUpdate: (data) => {
            const hasClips = data?.pages.some((response) =>
              response.data.some((item) => {
                if (isClipTableItem(item)) {
                  return item.data.assetId === newVersion.assetId;
                }
                return false;
              }),
            );
            return !!hasClips;
          },
          updateFn: (data) => updateVersionInTableData(data, newVersion),
        }),
      ]);

      if (shouldRemoveOriginalVersion) {
        await addAssetsToAllViews({
          parentBoardId,
          assets: [newVersion],
          type: getAssetGalleryItemType(newVersion),
        });
      }
    },
    [addAssetVersion, updateKanbanData, mutateGalleryData, mutateTableData, addAssetsToAllViews],
  );

  return {
    updateVersionByAssetId,
  };
};

interface UpdateAssetsInGalleryParams {
  assetIdentifiers: string[];
  updateFn?: (existingClip: Clip) => Clip;
  boardId?: Board['id'];
  identifier?: keyof Pick<Clip, 'id' | 'assetId'>;
  type?: GalleryItemType.asset | GalleryItemType.file;
}

/**
 * Use this hook to update assets in all views (gallery, table etc)
 */
export const useUpdateAssetsInAllViews = () => {
  const { mutateTableData, mutateGalleryData } = useMutateAssetsInAllViews();
  const { updateKanbanData } = useUpdateKanbanData();
  const updateAssetsInAllViews = useCallback(
    ({
      assetIdentifiers,
      boardId = '',
      updateFn = identity,
      identifier = 'assetId',
      type,
    }: UpdateAssetsInGalleryParams) =>
      Promise.all([
        updateKanbanData({
          assetIds: identifier === 'assetId' ? assetIdentifiers : undefined,
          clipIds: identifier === 'id' ? assetIdentifiers : undefined,
          updateClipFn: updateFn,
        }),
        mutateGalleryData({
          parentBoardId: boardId,
          shouldUpdate: (data) => {
            const idsSet = new Set(assetIdentifiers);
            const hasClip = data?.pages.some((items) => items.data.clips.some((clip) => idsSet.has(clip[identifier])));
            return !!hasClip;
          },
          updateFn: (data) => {
            if (data) {
              const idsSet = new Set(assetIdentifiers);

              return data.map((response) =>
                produce(response, (nextResponse) => {
                  nextResponse.data.clips = nextResponse.data.clips.map((clip) =>
                    idsSet.has(clip[identifier]) ? updateFn(clip) : clip,
                  );
                }),
              );
            }
          },
          types: type ? [type] : [GalleryItemType.file, GalleryItemType.asset],
        }),
        mutateTableData({
          parentBoardId: boardId,
          shouldUpdate: (data) => {
            const idsSet = new Set(assetIdentifiers);
            const hasClip = data?.pages.some((response) =>
              response.data.some((item) => {
                if (isClipTableItem(item)) {
                  return idsSet.has(item.data[identifier]);
                }
                return false;
              }),
            );
            return !!hasClip;
          },
          updateFn: (data) => {
            if (data) {
              const idsSet = new Set(assetIdentifiers);

              return data.map((response) =>
                produce(response, (nextResponse) => {
                  nextResponse.data.forEach((item) => {
                    if (isClipTableItem(item)) {
                      if ((!type || (type && item.type === type)) && idsSet.has(item.data[identifier])) {
                        item.data = updateFn(item.data);
                      }
                    }
                  });
                }),
              );
            }
          },
        }),
      ]),
    [updateKanbanData, mutateGalleryData, mutateTableData],
  );

  return {
    updateAssetsInAllViews,
  };
};

/**
 * Use this hook to update single asset in all views (gallery, table etc)
 */
export const useUpdateAssetInAllViews = () => {
  const { updateAssetsInAllViews } = useUpdateAssetsInAllViews();

  /**
   * Use this method to update asset in gallery
   * @param asset updated asset
   * @param parentBoardId board id
   * @param type type of asset (asset or file)
   * @param compareField field to check to determine if asset is the one we want to update. It's 'id' by default, usually 'id' or 'assetId'
   */
  const updateAssetInAllViews = useCallback(
    ({
      asset: nextAsset,
      parentBoardId = '',
      type,
      compareField = 'id',
      updateFn,
    }: {
      asset: Partial<Clip>;
      parentBoardId?: Board['id'];
      type?: GalleryItemType.asset | GalleryItemType.file;
      compareField?: keyof Pick<Clip, 'id' | 'assetId'>;
      updateFn?: (existingClip: Clip) => Clip;
    }) => {
      return updateAssetsInAllViews({
        assetIdentifiers: [nextAsset[compareField] as string],
        boardId: parentBoardId,
        updateFn: updateFn
          ? updateFn
          : (existingAsset) => ({
              ...existingAsset,
              ...nextAsset,
            }),
        identifier: compareField,
        type,
      });
    },
    [updateAssetsInAllViews],
  );

  return {
    updateAssetInAllViews,
  };
};

export type RemoveAssetsFromAllViewsParams = {
  parentBoardId?: Board['id'];
  type?: GalleryItemType.asset | GalleryItemType.file;
  queryKeyParams?: QueryKeyParamsType;
  removeIf?: (clip: Clip) => boolean;
} & (
  | {
      clipIds: Clip['id'][];
      assetIds?: never;
    }
  | {
      clipIds?: never;
      assetIds: Asset['id'][];
    }
);

/**
 * Use this hook to remove assets from all views (gallery, table etc)
 */
export const useRemoveAssetsFromAllViews = () => {
  const { mutateTableData, mutateGalleryData } = useMutateAssetsInAllViews();
  const { removeItems } = useUpdateItemsCount();
  const { removeKanbanItems } = useUpdateKanbanData();
  /**
   * Use this method to remove assets from gallery
   */
  const removeAssetsFromAllViews = useCallback(
    ({
      clipIds,
      assetIds,
      parentBoardId,
      type,
      queryKeyParams,
      removeIf = constant(false),
    }: RemoveAssetsFromAllViewsParams) => {
      if (parentBoardId && type) {
        removeItems(parentBoardId, !!clipIds ? clipIds.length : assetIds.length, type);
      }

      Promise.all([
        removeKanbanItems({ clipIds }),
        mutateGalleryData({
          parentBoardId: parentBoardId || '',
          queryKeyParams,
          shouldUpdate: (data) =>
            !!clipIds ? clipIdsExistsInGalleryView(clipIds, data) : assetIdsExistsInGalleryView(assetIds, data),
          updateFn: (data) =>
            !!clipIds
              ? getGalleryDataWithoutIds({ clipIds, data, removeIf })
              : getGalleryDataWithoutIds({ assetIds, data, removeIf }),
          types: type ? [type] : undefined,
        }),
        mutateTableData({
          parentBoardId: parentBoardId || '',
          queryKeyParams,
          shouldUpdate: (data) =>
            !!clipIds ? clipIdsExistsInTableView(clipIds, data) : assetIdsExistsInTableView(assetIds, data),
          updateFn: (data) =>
            !!clipIds
              ? getTableDataWithoutIds({ clipIds, data, removeIf })
              : getTableDataWithoutIds({ assetIds, data, removeIf }),
        }),
      ]);
    },
    [mutateGalleryData, mutateTableData, removeItems, removeKanbanItems],
  );

  return {
    removeAssetsFromAllViews,
  };
};

/**
 * Use this hook to refresh assets (refetch) in all views (gallery, table etc)
 */
export const useRefreshAssetsInAllViews = () => {
  const queryClient = useQueryClient();

  const refreshAssetsInAllViews = useCallback(
    ({ queryKeyParams, parentBoardId }: { parentBoardId?: Board['id']; queryKeyParams?: QueryKeyParamsType }) =>
      Promise.all([
        queryClient.invalidateQueries(getQueryKey({ mainKey: GALLERY_ASSETS, parentBoardId, queryKeyParams })),
        queryClient.invalidateQueries(getQueryKey({ mainKey: GALLERY_FILES, parentBoardId, queryKeyParams })),
        queryClient.invalidateQueries(getQueryKey({ mainKey: GALLERY_MIXED_DATA, parentBoardId, queryKeyParams })),
      ]),
    [queryClient],
  );

  return {
    refreshAssetsInAllViews,
  };
};

/**
 * Use this hook to update rearranged assets in gallery
 */
export const useRearrangeAssetsInGalleryView = () => {
  const { mutateGalleryData } = useMutateAssetsInAllViews();

  /**
   * Use this method to rearrange assets in gallery
   */
  const rearrangeAssetsInGalleryView = useCallback(
    (parentBoardId: Board['id'], updatedAssets: Clip[], type: GalleryItemType.asset | GalleryItemType.file) =>
      mutateGalleryData({
        parentBoardId,
        shouldUpdate: constant(true),
        updateFn: (data) => {
          if (!data) {
            return;
          }
          let previousAssetsSize = 0;

          return data.reduce((acc, curr) => {
            acc.push({
              ...curr,
              data: {
                ...curr.data,
                clips: updatedAssets.slice(previousAssetsSize, previousAssetsSize + curr.data.clips.length),
              },
            });
            previousAssetsSize += curr.data.clips.length;
            return acc;
          }, [] as ClipsListResponse<Clip>[]);
        },
        types: [type],
      }),
    [mutateGalleryData],
  );

  return {
    rearrangeAssetsInGalleryView,
  };
};

/**
 * Use this hook to update asset version in all views (gallery, table etc)
 */
export const useUpdateAssetVersionInAllViews = () => {
  const { addAssetsToAllViews } = useAddAssetsToAllViews();
  const { updateAssetInAllViews } = useUpdateAssetInAllViews();
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();

  /**
   * Use this method to update asset's version in all views
   * @param parentBoardId
   * @param oldVersion previous version which has to be updated
   * @param newVersion new version with fresh data
   */
  const updateAssetVersionInAllViews = useCallback(
    async ({
      parentBoardId,
      oldVersion,
      newVersion,
    }: {
      parentBoardId?: Board['id'];
      oldVersion: Clip;
      newVersion: Clip;
    }) => {
      const isOldAssetFile = isAssetFileType(oldVersion);
      const isNewAssetFile = isAssetFileType(newVersion);

      if (isOldAssetFile === isNewAssetFile) {
        await updateAssetInAllViews({
          asset: newVersion,
          parentBoardId,
          compareField: 'assetId',
          type: getAssetGalleryItemType(newVersion),
        });
      } else {
        await Promise.all([
          removeAssetsFromAllViews({
            clipIds: [oldVersion.id],
            parentBoardId,
            type: getAssetGalleryItemType(oldVersion),
          }),
          addAssetsToAllViews({ parentBoardId, assets: [newVersion], type: getAssetGalleryItemType(newVersion) }),
        ]);
      }
    },
    [addAssetsToAllViews, removeAssetsFromAllViews, updateAssetInAllViews],
  );

  return {
    updateAssetVersionInAllViews,
  };
};

export const useConvertAssetToVersionInAllViews = () => {
  const { updateAssetVersionInAllViews } = useUpdateAssetVersionInAllViews();
  const { updateAssetInAllViews } = useUpdateAssetInAllViews();
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  /**
   * Use this method to add new version to all views, which previosly was a separate asset (e.g. when drag one asset to the other)
   * @param parentBoardId
   *
   * @param draggedIds
   * @param targetAsset asset on which you drop another asset
   * @param newVersion dropped asset which has to be a new version
   */
  const convertAssetToVersionInAllViews = useCallback(
    async ({
      parentBoardId,
      draggedIds,
      targetAsset,
      newVersion,
    }: {
      parentBoardId?: Board['id'];
      draggedIds: Clip['id'][];
      targetAsset: Clip;
      newVersion: Clip;
    }) => {
      const idsToRemove = draggedIds.filter((id) => id !== newVersion.id);
      const isTargetAssetFile = isAssetFileType(targetAsset);
      const isNewAssetFile = isAssetFileType(newVersion);
      if (isTargetAssetFile === isNewAssetFile) {
        // if we merge images or files - remove dragged item and replace target asset by dragged
        await Promise.all([
          removeAssetsFromAllViews({ clipIds: [...idsToRemove, newVersion.id], parentBoardId }),
          updateAssetVersionInAllViews({
            parentBoardId,
            oldVersion: targetAsset,
            newVersion: {
              ...newVersion,
              // When we merge assets we need to keep custom fields from target asset
              customFields: targetAsset.customFields,
            },
          }),
        ]);
      } else {
        // if we merge images and files - remove dropped item and update dragged
        await Promise.all([
          removeAssetsFromAllViews({ clipIds: [...idsToRemove, targetAsset.id], parentBoardId }),
          updateAssetInAllViews({
            asset: newVersion,
            parentBoardId,
            type: getAssetGalleryItemType(newVersion),
          }),
        ]);
      }
    },
    [removeAssetsFromAllViews, updateAssetInAllViews, updateAssetVersionInAllViews],
  );

  return {
    convertAssetToVersionInAllViews,
  };
};

/**
 * Use this hook to update assets in Favorited page
 */
export const useUpdateFavoritedAssetsInAllViews = () => {
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  const { mutateTableData, mutateGalleryData } = useMutateAssetsInAllViews();
  const queryClient = useQueryClient();

  const updateFavoritedAssetsInAllViews = useCallback(
    async (clipIds: Clip['id'][], favorited: boolean) => {
      const account = queryClient.getQueryData<Account | null>(getAccountKey());
      if (!favorited && account?.id) {
        await removeAssetsFromAllViews({
          clipIds,
          queryKeyParams: { filters: { bookmarked: { is: account.id } } },
        });
      }

      await Promise.all([
        mutateGalleryData({
          parentBoardId: '',
          shouldUpdate: (data) => clipIdsExistsInGalleryView(clipIds, data),
          updateFn: (data) =>
            data?.reduce((acc, curr) => {
              acc.push({
                ...curr,
                data: {
                  ...curr.data,
                  clips: curr.data.clips.map((item) =>
                    clipIds.includes(item.id)
                      ? {
                          ...item,
                          bookmarked: favorited,
                        }
                      : item,
                  ),
                },
              });
              return acc;
            }, [] as ClipsListResponse<Clip>[]),
        }),
        mutateTableData({
          parentBoardId: '',
          shouldUpdate: (data) => clipIdsExistsInTableView(clipIds, data),
          updateFn: (data) =>
            data?.reduce((acc, curr) => {
              acc.push({
                ...curr,
                data: curr.data.map((item) => ({
                  ...item,
                  data:
                    isClipTableItem(item) && clipIds.includes(item.data.id)
                      ? {
                          ...item.data,
                          bookmarked: favorited,
                        }
                      : item.data,
                })),
              });
              return acc;
            }, [] as ListResponse<ClipAndBoardListItem>[]),
        }),
      ]);
    },
    [mutateGalleryData, mutateTableData, queryClient, removeAssetsFromAllViews],
  );

  return {
    updateFavoritedAssetsInAllViews,
  };
};

export const useRemoveAssetsWithoutTagsFromFilteredViews = () => {
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  const { removeFromSearchTotals } = useUpdateSearchTotals();

  const {
    filters: { tagIdOr = [], tagIdAnd = [], tagIdNot = [] },
  } = useFiltersContext();

  const removeAssetsWithoutTagsFromFilteredViews = useCallback(
    ({
      changedTags,
      clipIds,
      type,
    }: {
      clipIds: Clip['id'][];
      type?: GalleryItemType.asset | GalleryItemType.file;
      changedTags: Pick<Tag, 'label'>[];
    }) => {
      const changedTagsLabels = changedTags.map(({ label }) => label);

      const changedFilteredTagsAnd = intersection(tagIdAnd, changedTagsLabels);
      const changedFilteredTagsOr = intersection(tagIdOr, changedTagsLabels);
      const changedFilteredTagsNot = intersection(tagIdNot, changedTagsLabels);

      const removedClips: { [clipId: string]: Clip } = {};

      const removeIf = (clip: Clip) => {
        if (!clipIds.includes(clip.id)) {
          return false;
        }
        const clipTagsLabels = clip.tags.map(({ label }) => label);
        let shouldRemove = false;
        if (tagIdAnd.length > 0 && intersection(clipTagsLabels, tagIdAnd).length !== tagIdAnd.length) {
          shouldRemove = true;
        } else if (tagIdOr.length > 0 && intersection(clipTagsLabels, tagIdOr).length === 0) {
          shouldRemove = true;
        } else if (tagIdNot.length > 0 && intersection(clipTagsLabels, tagIdNot).length > 0) {
          shouldRemove = true;
        }
        if (shouldRemove) {
          removedClips[clip.id] = clip;
        }
        return shouldRemove;
      };

      if (changedFilteredTagsAnd.length > 0 || changedFilteredTagsOr.length > 0 || changedFilteredTagsNot.length > 0) {
        let queryKeyParams: DeepPartial<ClipsListOptions> = {};

        if (changedFilteredTagsAnd.length > 0) {
          queryKeyParams = { filters: { tag: { and: changedFilteredTagsAnd } } };
        } else if (changedFilteredTagsOr.length > 0) {
          queryKeyParams = { filters: { tag: { or: changedFilteredTagsAnd } } };
        }

        removeAssetsFromAllViews({
          clipIds: [],
          type,
          queryKeyParams,
          removeIf,
        });

        const removedClipsCount = intersection(Object.keys(removedClips), clipIds).length;
        removeFromSearchTotals(removedClipsCount);
      }
    },
    [removeAssetsFromAllViews, removeFromSearchTotals, tagIdAnd, tagIdNot, tagIdOr],
  );

  return {
    removeAssetsWithoutTagsFromFilteredViews,
  };
};

export const useRemoveAssetsFromUnusedAssetsView = () => {
  const { mutateTableData, mutateGalleryData } = useMutateAssetsInAllViews();

  const removeAssetsFromUnusedAssetsView = useCallback(
    ({ clipIds }: { clipIds: Clip['id'][] }) => {
      const queryKeyParams = { filters: { isOnBoards: { is: false } } };

      return Promise.all([
        mutateGalleryData({
          parentBoardId: '',
          shouldUpdate: (data) => clipIdsExistsInGalleryView(clipIds, data),
          queryKeyParams,
          updateFn: (data) => getGalleryDataWithoutIds({ clipIds, data }),
        }),
        mutateTableData({
          parentBoardId: '',
          shouldUpdate: (data) => clipIdsExistsInTableView(clipIds, data),
          queryKeyParams,
          updateFn: (data) => getTableDataWithoutIds({ clipIds, data }),
        }),
      ]);
    },
    [mutateGalleryData, mutateTableData],
  );

  return {
    removeAssetsFromUnusedAssetsView,
  };
};

export const useRemoveAssetsWithoutCFFromFilteredViews = () => {
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  const { removeFromSearchTotals } = useUpdateSearchTotals();
  const {
    filters: { customFields = {} },
  } = useFiltersContext();

  const removeAssetsWithoutCFFromFilteredViews = useCallback(
    async ({
      assetIds,
      type,
      customFieldId,
    }: {
      assetIds: Clip['assetId'][];
      type?: GalleryItemType.asset | GalleryItemType.file;
      customFieldId: CustomField['id'];
    }) => {
      const cfAndParam = `${[FilterParamNames.cfIdAnd]}${customFieldId}`;
      const cfOrParam = `${[FilterParamNames.cfIdOr]}${customFieldId}`;
      const cfNotParam = `${[FilterParamNames.cfIdNot]}${customFieldId}`;

      const removedClips: { [assetId: string]: Clip } = {};

      const removeIf = (clip: Clip) => {
        if (!assetIds.includes(clip.assetId)) {
          return false;
        }

        let shouldRemove = false;

        for (const cfParamName in customFields) {
          if (cfParamName === cfAndParam) {
            shouldRemove = true;
          } else {
            const cfId = customFieldIdFromUrlParam(cfParamName);
            const clipCF = clip.customFields?.find((cf) => cf.id === cfId);

            if (clipCF) {
              const clipCFValues = clipCF.value ? [clipCF.value.id] : clipCF.values?.map(({ id }) => id) ?? [];
              const commonCFs = intersection(customFields[cfParamName], clipCFValues);

              if (cfParamName === cfOrParam) {
                shouldRemove = !commonCFs.length;
              } else if (cfParamName === cfNotParam) {
                shouldRemove = !!commonCFs.length;
              }
            }
          }
        }

        if (shouldRemove) {
          removedClips[clip.assetId] = clip;
        }

        return shouldRemove;
      };

      removeAssetsFromAllViews({
        clipIds: [],
        type,
        queryKeyParams: {
          filters: {
            customFields: [
              {
                id: customFieldId,
              },
            ],
          },
        },
        removeIf,
      });

      const removedClipsCount = intersection(Object.keys(removedClips), assetIds).length;
      if (Object.keys(customFields).length > 0) {
        removeFromSearchTotals(removedClipsCount);
      }
    },
    [customFields, removeAssetsFromAllViews, removeFromSearchTotals],
  );

  return {
    removeAssetsWithoutCFFromFilteredViews,
  };
};
