import { Clips } from '@air/api';
import { Clip, ClipGetOptions } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

export type ClipGetOptionsWithBoards = ClipGetOptions & { withBoards: true };

export const defaultClipGetOptions: ClipGetOptionsWithBoards = {
  withBoards: true,
  withAncestorsAndThumbnails: true,
  withOpenDiscussionStatus: true,
  numBoardThumbnails: 1,
};

export const getClipWithoutWorkspaceIdKey = ({
  clipId,
  options,
}: {
  clipId: string;
  options?: ClipGetOptions;
}): [string, Partial<ClipGetOptions> & { clipId: string }] => ['clips', { clipId, ...options }];

export interface UseGetClipWithNoWorkspaceIdProps<Options extends ClipGetOptions> {
  clipId?: Clip['id'];
  options?: Options;
}

export const useGetClipWithNoWorkspaceId = <Options extends ClipGetOptions>({
  clipId,
  options,
}: UseGetClipWithNoWorkspaceIdProps<Options>) => {
  return useQuery(
    clipId ? getClipWithoutWorkspaceIdKey({ clipId, options }) : [],
    () => {
      if (!clipId) {
        throw new Error('clipId is required');
      }

      return Clips.getWithNoWorkspaceId({ id: clipId, options });
    },
    {
      enabled: !!clipId,
    },
  );
};
