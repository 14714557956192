import { TrackFavoritedBoardParams } from '@air/analytics';
import { Board } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { useCallback } from 'react';

import { getFavoritedMessage } from '~/constants/messages';
import { useFollowBoard } from '~/swr-hooks/followedBoards/useFollowBoard';

import { useFavoriteBoards } from './useFavoriteBoards';
import { useUnfavoriteBoards } from './useUnfavoriteBoards';

/**
 * This hook gives you a function to pass clips to that will toggle their current FavoritedBoard
 * state. If any of them are currently unfavorited, it will favorite all of them. If all
 * of them are currently FavoritedBoard, it will unfavorite all of them.
 */
export const useToggleFavoritedBoard = () => {
  const { showToast } = useToasts();
  const { favoriteBoards } = useFavoriteBoards();
  const { unfavoriteBoards } = useUnfavoriteBoards();
  const {
    followBoard: { isLoading, mutate },
  } = useFollowBoard();

  const toggleFavoritedBoard = useCallback<
    (args: { board: Board; trackLocation: TrackFavoritedBoardParams['location'] }) => void
  >(
    async ({ board, trackLocation }) => {
      const successful = !board.hasCurrentUser
        ? await favoriteBoards({
            boards: [board],
            trackLocation,
          })
        : await unfavoriteBoards({
            boards: [board],
            trackLocation,
          });

      if (successful) {
        showToast(getFavoritedMessage(!board.hasCurrentUser, undefined, board.title), {
          timeBeforeAutoDismiss: 2000,
          suffix: !board.hasCurrentUser && (
            <Button
              appearance="ghost"
              color="grey"
              isLoading={isLoading}
              onClick={() => {
                mutate(board.id, {
                  onSuccess: () => {
                    showToast(`You will receive notifications about changes in ${board.title}`);
                  },
                });
              }}
            >
              Follow
            </Button>
          ),
        });
      }
    },
    [favoriteBoards, isLoading, mutate, showToast, unfavoriteBoards],
  );

  return {
    toggleFavoritedBoard,
  };
};
