import { ClipsListResponse } from '@air/api';
import { Asset, Clip, ClipAndBoardListItem, ListResponse } from '@air/api/types';

import { QueryInfiniteData } from '~/swr-hooks/queryClient';
import { isClipTableItem } from '~/utils/tableViewDataUtils';

export const clipIdsExistsInGalleryView = (
  clipIds: Clip['id'][],
  data?: QueryInfiniteData<ClipsListResponse<Clip>>,
) => {
  const hasClip = data?.pages.some((item) => item.data.clips.some((clip) => clipIds.includes(clip.id)));
  return !!hasClip;
};

export const clipIdsExistsInTableView = (
  clipIds: Clip['id'][],
  data?: QueryInfiniteData<ListResponse<ClipAndBoardListItem>>,
) => {
  const hasClips = data?.pages.some((response) =>
    response.data.some((item) => {
      if (isClipTableItem(item)) {
        return clipIds.includes(item.data.id);
      }
      return false;
    }),
  );
  return !!hasClips;
};

export const assetIdsExistsInGalleryView = (
  assetIds: Asset['id'][],
  data: QueryInfiniteData<ClipsListResponse<Clip>>,
) => {
  const hasAsset = data?.pages.some((item) => item.data.clips.some((clip) => assetIds.includes(clip.assetId)));

  return !!hasAsset;
};

export const assetIdsExistsInTableView = (
  assetIds: Asset['id'][],
  data?: QueryInfiniteData<ListResponse<ClipAndBoardListItem>>,
) => {
  const hasAssets = data?.pages.some((response) =>
    response.data.some((item) => {
      if (isClipTableItem(item)) {
        return assetIds.includes(item.data.assetId);
      }
      return false;
    }),
  );

  return !!hasAssets;
};
