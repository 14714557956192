import { BoardsListOptions, Library, SortDirection, SubnavBoardSort } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';

import { usePrivateBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePrivateBoardsFetcher';
import { useSubnavSort } from '~/swr-hooks/subnav/useSubnavSort';

export const LIBRARY_BOARDS_LIST = 'LIBRARY_BOARDS_LIST';

export const getLibraryRootBoardsKey = (params: {
  workspaceId?: string;
  sortField?: SubnavBoardSort;
  libraryId: Library['id'] | undefined;
}) => {
  return [LIBRARY_BOARDS_LIST, params];
};

export const useLibraryRootBoards = (
  workspaceId: string | undefined,
  libraryId: Library['id'] | undefined,
  params?: Partial<BoardsListOptions>,
) => {
  const { privateBoardsFetcher } = usePrivateBoardsFetcher();
  const { data: subnavSort } = useSubnavSort();
  const sortField = subnavSort?.boardSort;

  const inifiteQuery = useInfiniteQuery(
    getLibraryRootBoardsKey({
      workspaceId,
      sortField,
      libraryId,
    }),
    ({ pageParam }) => {
      return privateBoardsFetcher({
        params: {
          isMemberOfBoard: 'ALL',
          limit: 100,
          view: 'root',
          includeAncestors: true,
          numThumbnails: 0,
          sortField: sortField ? { name: sortField.name, direction: sortField.direction } : undefined,
          libraryBoards: 'YES',
          libraryIds: libraryId ? [libraryId] : undefined,
          ...params,
        },
        sortFieldName: sortField?.name || 'customSort',
        sortDirection: sortField?.direction || SortDirection.desc,
        // TODO: Just pass the pageParam as an actual param once everything using `privateBoardsFetcher` has been
        // moved to react-query
      })(pageParam ? `?cursor=${pageParam}` : '');
    },
    {
      enabled: !!workspaceId && !!libraryId,
      getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    },
  );

  return {
    data: inifiteQuery.data?.pages.flatMap((d) => d.data) ?? [],
    hasMore: !!inifiteQuery.hasNextPage,
    loadNextPage: inifiteQuery.fetchNextPage,
    isLoadingMore: inifiteQuery.isFetchingNextPage,
    isInitialLoading: inifiteQuery.isLoading,
  };
};
