import { useAnalyticsUtilities } from '@air/analytics';
import LogRocket from 'logrocket';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { EnhancedSubscription, useSubscription } from '~/swr-hooks/subscriptions/useSubscription';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { useAccountContext } from './AccountProvider';

export type { EnhancedSubscription } from '~/swr-hooks/subscriptions/useSubscription';

interface SubscriptionProviderProps extends PropsWithChildren<{}> {}

export interface SubscriptionProviderContextValue {
  data: EnhancedSubscription | undefined;
  error?: unknown;
}

const defaultValue: SubscriptionProviderContextValue = {
  data: undefined,
};

const SubscriptionProviderContext = createContext<SubscriptionProviderContextValue>(defaultValue);

export const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const { data: account } = useAccountContext();
  const { setGlobalEventProps } = useAnalyticsUtilities();
  const { data: subscription, error } = useSubscription();

  const value = useMemo(() => ({ data: subscription, error }), [subscription, error]);

  if (subscription) {
    if (account) {
      LogRocket.identify(account.email, {
        plan: subscription.plan,
      });
    }

    setGlobalEventProps({
      plan: subscription.plan,
    });
  }

  return <SubscriptionProviderContext.Provider value={value}>{children}</SubscriptionProviderContext.Provider>;
};

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionProviderContext);

  if (context === defaultValue) {
    const error = 'SubscriptionProviderContext used outside of SubscriptionProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
