import { Subnav } from '@air/api';
import { SortDirection, Subnav as SubnavType, WorkspaceInfo } from '@air/api/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const SUBNAV_SORT = 'SUBNAV_SORT';

export const getWorkspaceSubnavSortKey = (workspaceId?: WorkspaceInfo['id']) => [SUBNAV_SORT, { workspaceId }];

export const useSubnavSort = (params?: { enabled?: boolean }) => {
  const { enabled = true } = params ?? { enabled: true };
  const { currentWorkspace } = useCurrentWorkspace();
  const key = getWorkspaceSubnavSortKey(currentWorkspace?.id);
  return useQuery(
    key,
    async () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const response = await Subnav.getSubnav({ workspaceId });

      // TODO: This logic should likely live in the backend, there is a ticket
      // for this but we are writing this here in the meantime to fix bugs[1] and
      // provide the right UX.
      // [1]: https://air-labs-team.slack.com/archives/CKE8SK3M1/p1645652666618349

      return {
        ...response,
        boardSort: {
          name: 'customSort',
          direction: SortDirection.desc,
          ...response?.boardSort,
        },
      };
    },
    {
      enabled: enabled && !!currentWorkspace,
    },
  );
};

export const useGetSubnavSortValue = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const getSubNavSortValue = useCallback(() => {
    const key = getWorkspaceSubnavSortKey(currentWorkspace?.id);

    return queryClient.getQueryData<SubnavType>(key);
  }, [currentWorkspace?.id, queryClient]);

  return {
    getSubNavSortValue,
  };
};
