import { Workspaces } from '@air/api';
import { Workspace } from '@air/api/types';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';

export const WORKSPACE = 'WORKSPACE';

export const getWorkspaceKey = (id?: string) => {
  return [WORKSPACE, id];
};

export type UseWorkspaceParams = {
  id?: Workspace['id'];
};

export const useWorkspace = ({ id }: UseWorkspaceParams) => {
  const { isLoggedIn } = useIsLoggedIn();

  return useQuery(
    getWorkspaceKey(id),
    () => {
      if (!id) {
        throw new Error('Workspace ID is not defined');
      }

      return Workspaces.get({ workspaceId: id });
    },
    {
      enabled: isLoggedIn && !!id,
    },
  );
};
