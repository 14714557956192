import { ShortUrlResponse } from '@air/api/types';
import { merge } from 'lodash';
import { useCallback } from 'react';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { useRevalidatePublicBoard } from '~/hooks/useRevalidatePublicBoard';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { UpdateShortIdParams, useUpdateShortId } from '~/swr-hooks/shortUrl/useUpdateShortId';

interface UseUpdateShareBoardLinkParams {
  boardId: string;
  shortIdInfo: ShortUrlResponse;
}

export const useUpdateShareBoardLink = ({ boardId, shortIdInfo }: UseUpdateShareBoardLinkParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { updateShortId } = useUpdateShortId();
  const { revalidatePublicBoard } = useRevalidatePublicBoard();

  const onShortUrlUpdate = useCallback(
    async (
      props: DeepPartial<Omit<UpdateShortIdParams, 'objectId' | 'id' | 'boardContext' | 'shortId'>>,
    ): Promise<ShareUrlResponseWithSource> => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const response = await updateShortId.mutateAsync({
        workspaceId,
        ...merge({}, shortIdInfo, props),
        objectId: boardId,
        shortId: shortIdInfo.id,
        boardContext: shortIdInfo.boardContext,
      });

      /**
       * Don't await since this can be slow
       * @see https://air-labs-team.slack.com/archives/CSU3B8B3R/p1699565392614499?thread_ts=1699564602.295969&cid=CSU3B8B3R
       */
      revalidatePublicBoard(boardId);

      return {
        ...response,
        objectId: boardId,
        objectType: 'board',
      };
    },
    [boardId, currentWorkspace?.id, revalidatePublicBoard, shortIdInfo, updateShortId],
  );

  return {
    onShortUrlUpdate,
    isLoading: updateShortId.isLoading,
  };
};
