import { useTrackOpenedPlansModal } from '@air/analytics';
import { CurrentPlanPricing } from '@air/api/types';
import { Modal, ModalCloseButton } from '@air/primitive-modal';
import { Spinner } from '@air/primitive-spinner';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { memo, useEffect } from 'react';

import { PlanInfo } from '~/components/Modals/PlansModal/components/PlanInfo';
import { planPricingToPlanInfo } from '~/components/Modals/PlansModal/utils';
import { Routes } from '~/constants/routes';
import { PAID_PLANS_MODAL_TITLE } from '~/constants/testIDs';
import { NEW_TAB } from '~/constants/urls';
import { usePlansList } from '~/hooks/usePlansList';
import { useAccountContext } from '~/providers/AccountProvider';
import { EnhancedSubscription } from '~/providers/SubscriptionProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface UpgradePlanModalProps {
  subscription: EnhancedSubscription;
}

export const UpgradePlanModal = memo(({ onClose, subscription }: AirModalProps<UpgradePlanModalProps>) => {
  const { data, error, isInitialLoading: isPlansListLoading } = usePlansList();
  const plans = data?.plans ?? [];
  const { trackOpenedPlansModal } = useTrackOpenedPlansModal();
  const { isAboveSmallScreen } = useBreakpointsContext();
  const { fixedPricing: fixedPricingFlag } = useFlags();

  const { data: account } = useAccountContext();

  useEffect(() => {
    if (error) {
      reportErrorToBugsnag({ error, context: 'Failed to fetch plans from PaidPlans modal' });
    }
  }, [error]);

  const plansInfo = plans.map((plan) =>
    planPricingToPlanInfo(plan as CurrentPlanPricing, subscription?.plan, fixedPricingFlag),
  );

  useEffect(() => {
    trackOpenedPlansModal();
  }, [trackOpenedPlansModal]);

  return (
    <Modal dangerouslyBypassFocusLock isOpen onDismiss={onClose} className="mt-8 w-full max-w-[1200px] p-0">
      <>
        <div className="flex justify-between px-4 pt-6 sm:px-8 sm:pt-8">
          <div>
            <h1 aria-hidden={true} className="mb-2.5 text-24 font-semibold" data-testid={PAID_PLANS_MODAL_TITLE}>
              {!isAboveSmallScreen || !account ? 'Choose your plan' : `Choose your plan, ${account?.firstName}`}
            </h1>
            <div className="flex flex-col justify-between">
              <div className="flex flex-col items-center justify-between pb-4 sm:flex-row sm:pb-5">
                <p className="px-6 text-center text-14 sm:px-0 sm:text-left">
                  Pay annually to save 17%! To learn more about our plans, check out our{' '}
                  <Link
                    className="text-blue-10"
                    href={Routes.marketing.pricing}
                    target={NEW_TAB}
                    rel="noopener noreferrer"
                  >
                    Pricing Page
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <ModalCloseButton onClick={onClose} />
        </div>

        <div className="flex flex-col justify-center border-t border-t-grey-5 sm:flex-row">
          {isPlansListLoading ? (
            <div className="flex min-h-[556px] flex-1 items-center justify-center">
              <Spinner className="text-teal-9" />
            </div>
          ) : (
            plansInfo.map((plan) => (
              <PlanInfo key={plan.id} {...plan} subscribed={subscription.subscribed} onClose={onClose} />
            ))
          )}
        </div>
      </>
    </Modal>
  );
});

UpgradePlanModal.displayName = 'UpgradePlanModal';
