import { Subscriptions } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const SUBSCRIPTIONS_LIST_PLANS = 'SUBSCRIPTIONS_LIST_PLANS';

export const getSubscriptionPlansListKey = (workspaceId?: string) => {
  return workspaceId ? [SUBSCRIPTIONS_LIST_PLANS, { workspaceId }] : [SUBSCRIPTIONS_LIST_PLANS];
};

export const usePlansList = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  return useQuery(
    getSubscriptionPlansListKey(),
    () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Subscriptions.listPlans({ workspaceId });
    },
    {
      enabled: !!currentWorkspace?.id,
    },
  );
};
