import { createSelector } from 'reselect';

import {
  getBoardDeletionTasksFromTasks,
  getCTWTasksFromTasks,
  getDuplicatBoardTasksFromTasks,
  getLibraryDeletionTasksFromTasks,
  getZippingTasksFromTasks,
} from '~/store/tasks/utils';
import { AirState } from '~/store/types';

const baseTasksSelector = ({ tasks }: AirState) => tasks;

export const tasksSelector = createSelector(baseTasksSelector, ({ tasks }) => tasks);

export const tasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(baseTasksSelector, ({ tasks }) =>
    tasks.filter((task) => !task.workspaceId || task.workspaceId === workspaceId),
  );

export const tasksSelectorByShortId = (shortId?: string) =>
  createSelector(baseTasksSelector, ({ tasks }) => tasks.filter((task) => !task.shortId || task.shortId === shortId));

export const zippingTasksSelector = createSelector(tasksSelector, (tasks) => getZippingTasksFromTasks(tasks));

export const makeZippingTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getZippingTasksFromTasks(tasks));

export const makeZippingTasksSelectorByShortId = (shortId?: string) =>
  createSelector(tasksSelectorByShortId(shortId), (tasks) => getZippingTasksFromTasks(tasks));

export const makeCopyToWorkspaceTasksSelectorByShortId = (shortId?: string) =>
  createSelector(tasksSelectorByShortId(shortId), (tasks) => getCTWTasksFromTasks(tasks));

export const makeDuplicateBoardTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getDuplicatBoardTasksFromTasks(tasks));

export const makeBoardDeletionTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getBoardDeletionTasksFromTasks(tasks));

export const makeLibraryDeletionTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getLibraryDeletionTasksFromTasks(tasks));

export const boardIdsBeingDeletedSelector = createSelector(tasksSelector, (tasks) =>
  getBoardDeletionTasksFromTasks(tasks).flatMap((task) => (task.status !== 'error' ? task.metadata.boardIds : [])),
);

export const libraryIdsBeingDeletedSelector = createSelector(tasksSelector, (tasks) =>
  getLibraryDeletionTasksFromTasks(tasks).flatMap((task) => (task.status !== 'error' ? task.metadata.libraryId : [])),
);
