import { Board } from '@air/api/types';
import { Slack as SlackIcon } from '@air/next-icons';
import { useToasts } from '@air/provider-toast';
import { Button } from '@air/zephyr';
import { memo } from 'react';

import { useConnectToSlack } from '../hooks/useConnectToSlack';

interface ConnectToSlackProps {
  board: Board;
}

export const ConnectToSlack = memo(({ board }: ConnectToSlackProps) => {
  const { connectToSlack } = useConnectToSlack();
  const { showToast } = useToasts();

  const onConnectToSlack = () => {
    try {
      connectToSlack({ board, trackLocation: 'edit-board-details-modal' });
    } catch (error) {
      showToast('There was an error with slack authentication. Please try again later', {
        timeBeforeAutoDismiss: null,
        type: 'assertive',
        withCloseButton: true,
      });
    }
  };

  return (
    <Button
      variant="button-filled-grey"
      tx={{
        width: '100%',
        justifyContent: 'flex-start',
        fontWeight: 'regular',
        height: 48,
        mt: 8,
      }}
      adornmentLeft={SlackIcon}
      onClick={onConnectToSlack}
    >
      Connect to Slack
    </Button>
  );
});

ConnectToSlack.displayName = 'ConnectToSlack';
