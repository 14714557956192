import { Permissions } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { ONE_DAY } from '~/constants/unitsOfTime';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const getAnyPermissionsKey = (workspaceId: string | undefined) => ['ANY_PERMISSIONS', workspaceId];

export const useFetchAnyWorkspacePermissions = () => {
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery(
    getAnyPermissionsKey(currentWorkspace?.id),
    async () => {
      invariant(currentWorkspace?.id, 'No current workspace');

      const workspaceId = currentWorkspace.id;

      return Permissions.getFromObjects({
        workspaceId,
        mode: 'any',
        objects: undefined,
      });
    },
    {
      staleTime: ONE_DAY,
      enabled: !!currentWorkspace?.id,
    },
  );
};
