import { Workspaces } from '@air/api';
import { WorkspaceMemberType } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const WORKSPACE_ROLES = 'WORKSPACE_ROLES';

export const getWorkspaceRolesKey = (workspaceId?: string, memberType?: WorkspaceMemberType) => [
  WORKSPACE_ROLES,
  { workspaceId, memberType },
];

export const useWorkspaceRoles = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery(
    getWorkspaceRolesKey(workspaceId),
    () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return Workspaces.getRoles({ workspaceId });
    },
    {
      staleTime: Infinity,
      enabled: !!workspaceId,
    },
  );
};
