import { OpenAssetModalLocations, useTrackOpenedAssetModal } from '@air/analytics';
import Router from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { QueryParamNames, QueryType } from '~/constants/search';
import { setCentralizedClipAction } from '~/store/centralizedClip/actions';
import { pushWithExistingQuery } from '~/utils/PathUtils';

export interface GoToAssetPageProps {
  asset: Parameters<typeof setCentralizedClipAction>[0]['clip'];
  base?: string;
  query?: QueryType;
  trackLocation?: OpenAssetModalLocations;
}

export function useGoToAssetPage() {
  const dispatch = useDispatch();
  const { trackOpenedAssetModal } = useTrackOpenedAssetModal();

  const goToAssetPage = useCallback(
    ({ asset, query, base = Router.asPath, trackLocation = 'not-set' }: GoToAssetPageProps) => {
      Router.ready(() => {
        /**
         * Split away any query params
         * Split away a previous clip id that might be there
         */
        const basePath = base.split('?')[0].split('/c/')[0];
        const assetPath = `/c/${asset.id}`;
        const path = `${basePath}${assetPath}`;

        if (Router.asPath.includes(asset.id)) return;

        dispatch(
          setCentralizedClipAction({
            clip: asset,
          }),
        );

        pushWithExistingQuery({
          path,
          newQuery: {
            ...query,
            [QueryParamNames.notificationsPanel]: null,
            [QueryParamNames.timestamp]: null,
            [QueryParamNames.discussionId]: null,
            [QueryParamNames.page]: null,
          },
        });

        trackOpenedAssetModal({ location: trackLocation });
      });
    },
    [dispatch, trackOpenedAssetModal],
  );

  return {
    goToAssetPage,
  };
}
