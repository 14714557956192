import { SortDirection } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';

import { usePrivateBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePrivateBoardsFetcher';

const FAVORITE_BOARDS = 'FAVORITE_BOARDS';

export const getFavoriteBoardsKey = (workspaceId?: string) => {
  return [FAVORITE_BOARDS, { workspaceId }];
};

export const useFavoriteBoardsList = (workspaceId?: string) => {
  const { privateBoardsFetcher } = usePrivateBoardsFetcher();

  const inifiteQuery = useInfiniteQuery(
    getFavoriteBoardsKey(workspaceId),
    ({ pageParam }) => {
      return privateBoardsFetcher({
        params: {
          isMemberOfBoard: 'YES',
          limit: 100,
          includeAncestors: true,
          numThumbnails: 0,
        },
        sortFieldName: 'customSort',
        sortDirection: SortDirection.desc,
        // TODO: Just pass the pageParam as an actual param once everything using `privateBoardsFetcher` has been
        // moved to react-query
      })(pageParam ? `?cursor=${pageParam}` : '');
    },
    {
      enabled: !!workspaceId,
      getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    },
  );

  return {
    data: inifiteQuery.data?.pages.flatMap((d) => d.data) ?? [],
    hasMore: !!inifiteQuery.hasNextPage,
    loadNextPage: inifiteQuery.fetchNextPage,
    isLoadingMore: inifiteQuery.isFetchingNextPage,
    isInitialLoading: inifiteQuery.isLoading,
  };
};
