import { Items } from '@air/api';
import { Board, Clip, ClipAndBoardListItem, ClipType, ViewTypeName, VisibleColumnType } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import produce from 'immer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { centralizedBoardWorkspaceIdSelector } from '~/store/centralizedBoard/selectors';
import {
  currentKanbanGroupByCustomFieldIdSelector,
  currentViewIdSelector,
  isKanbanViewSelector,
  isSortFieldKanbanDefaultSelector,
} from '~/store/configViews/selectors';
import { removeKanbanItemsAction, updateKanbanColumnWithNewDataAction } from '~/store/kanbanManager/actions';
import {
  findKanbanItemByAssetIdInColumnSelector,
  findKanbanItemByClipIdInColumnSelector,
  findKanbanItemInColumnSelector,
  kanbanColumnItemsSelector,
} from '~/store/kanbanManager/selectors';
import { DndItemType, DndSortableKanbanItemData } from '~/types/DndKit';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getBoardIdFromPath, getShortIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useUpdateKanbanData = () => {
  const { getState } = useAirStore();
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const { isSearchActive } = useIsSearchActive();

  const updateKanbanData = useCallback(
    async ({
      assetIds,
      clipIds,
      boardIds,
      updateClipFn,
      updateBoardFn,
    }: {
      assetIds?: Clip['assetId'][];
      clipIds?: Clip['id'][];
      boardIds?: Board['id'][];
      updateClipFn?: (clip: Clip) => Clip;
      updateBoardFn?: (board: Board) => Board;
    }) => {
      const state = getState();

      // kanban refetches each time we come to it, so we don't need
      // an update to the data if we are not currently in that view
      const isKanbanView = isKanbanViewSelector(state);
      if (!isKanbanView) return;
      const customFieldId = currentKanbanGroupByCustomFieldIdSelector(state)!; // id must exist in kanban view

      const columnsToUpdateWithDndItems: Record<string, DndSortableKanbanItemData[]> = {};
      const moveItemsBeforeIds: Record<string, string[]> = {};

      let hasAnyItemMovedColumns = false;

      const handleItem = ({
        columnId,
        columnItems,
        item,
      }: {
        columnId: string;
        columnItems: DndSortableKanbanItemData[];
        item: DndSortableKanbanItemData;
      }) => {
        // ensure we hang on to the column we will update later
        if (!columnsToUpdateWithDndItems[columnId]) columnsToUpdateWithDndItems[columnId] = [...columnItems];

        const clipOrBoard = item.apiData.data as Clip | Board;
        let updatedClipOrBoard: Clip | Board | undefined = undefined;
        if (item.dndType === DndItemType.kanbanBoard && updateBoardFn) {
          const board = clipOrBoard as Board;
          updatedClipOrBoard = produce(board, (draft) => updateBoardFn(draft));
        } else if ([DndItemType.kanbanAsset, DndItemType.kanbanFile].includes(item.dndType) && updateClipFn) {
          const clip = clipOrBoard as Clip;
          updatedClipOrBoard = produce(clip, (draft) => updateClipFn(draft));
        }
        if (!updatedClipOrBoard) return;
        // don't use item.originalKanbanColumnId to account for unassigned values
        // cast JUST to get the customFields, whichs hould be fine as Asset or BoardCustomFieldValues here
        const originalCFValue = (clipOrBoard as Clip).customFields?.find((i) => i.id === customFieldId);
        const updatedCFValue = (updatedClipOrBoard as Clip).customFields?.find((i) => i.id === customFieldId);

        // if undefined === undefined then single value did not change and we can just update the item in place
        if (originalCFValue?.value?.id === updatedCFValue?.value?.id) {
          // change item in place
          const itemIndex = columnsToUpdateWithDndItems[columnId].findIndex((i) => i.itemId === item.itemId);
          if (itemIndex !== -1) {
            columnsToUpdateWithDndItems[columnId][itemIndex] = produce(
              columnsToUpdateWithDndItems[columnId][itemIndex],
              (draft) => {
                draft.apiData.data = updatedClipOrBoard as object;
              },
            );
          }
        } else {
          hasAnyItemMovedColumns = true;
          const originalColumnId = item.currentKanbanColumnId;
          const destinationColumnId = updatedCFValue?.value?.id || VisibleColumnType.unassignedCustomFieldValue;
          if (!columnsToUpdateWithDndItems[destinationColumnId]) {
            columnsToUpdateWithDndItems[destinationColumnId] = kanbanColumnItemsSelector(state, destinationColumnId);
          }
          const topMostItemId = kanbanColumnItemsSelector(state, destinationColumnId)[0]?.itemId;
          if (topMostItemId) {
            if (!moveItemsBeforeIds[topMostItemId]) moveItemsBeforeIds[topMostItemId] = [];
            moveItemsBeforeIds[topMostItemId].push(item.itemId);
          }
          // manipulate kanban columns
          columnsToUpdateWithDndItems[originalColumnId] = columnsToUpdateWithDndItems[originalColumnId].filter(
            (i) => i.itemId !== item.itemId,
          );
          columnsToUpdateWithDndItems[destinationColumnId] = [
            produce(item, (draft) => {
              draft.apiData.data = updatedClipOrBoard as object;
            }),
            ...columnsToUpdateWithDndItems[destinationColumnId],
          ];
        }
      };

      clipIds?.forEach((clipId) => {
        const { columnId, columnItems, item } = findKanbanItemByClipIdInColumnSelector(state, clipId);
        if (!item || !columnId || !columnItems) return;
        handleItem({ item, columnId, columnItems });
      });
      assetIds?.forEach((assetId) => {
        const { columnId, columnItems, item } = findKanbanItemInColumnSelector(state, assetId);
        if (!item || !columnId || !columnItems) return;
        handleItem({ item, columnId, columnItems });
      });
      boardIds?.forEach((boardId) => {
        const { columnId, columnItems, item } = findKanbanItemInColumnSelector(state, boardId);
        if (!item || !columnId || !columnItems) return;
        handleItem({ item, columnId, columnItems });
      });

      const columnsToUpdate: Record<string, ClipAndBoardListItem[]> = {};
      for (const [key, dndItems] of Object.entries(columnsToUpdateWithDndItems)) {
        columnsToUpdate[key] = dndItems
          .filter((item) => item.dndType !== DndItemType.kanbanUpload)
          .map((i) => i.apiData);
      }

      dispatch(updateKanbanColumnWithNewDataAction({ columnsToUpdate }));
      const isSortFieldKanbanDefault = isSortFieldKanbanDefaultSelector(state);
      if (hasAnyItemMovedColumns && (isSearchActive || !isSortFieldKanbanDefault)) {
        showToast(
          'One or more item(s) moved to new column. For accurate item position, refresh the page or set to custom sort and reset search query.',
        );
      }
      const shortId = getShortIdFromPath(window.location.pathname);
      try {
        await Promise.all(
          Object.keys(moveItemsBeforeIds).map((moveBeforeId) => {
            const boardId = getBoardIdFromPath(window.location.pathname);
            if (shortId || !boardId) {
              // TODO: Update public positions
              return;
            }

            /**
             * This is a hack to get the workspaceId from the centralized board store. We shouldn't
             * be doing this but we would have to do a lot of refactors to get the workspaceId passed
             * down into this level.
             */
            const workspaceId = centralizedBoardWorkspaceIdSelector(getState());

            if (!workspaceId) {
              throw new Error('workspaceId is required');
            }

            return Items.updateItemPositions({
              workspaceId,
              idsToMove: moveItemsBeforeIds[moveBeforeId],
              betweenIds: { moveBeforeId },
              boardId,
              view: {
                id: currentViewIdSelector(state)!, // at this stage we should have this to cast
                type: ViewTypeName.kanban,
              },
            });
          }),
        );
      } catch (error) {
        showToast('There was an error updating the kanban board. Please refresh the page');
        reportErrorToBugsnag({
          error,
          context: 'Failed to update item positions',
          metadata: {
            data: {
              moveItemsBeforeIds,
            },
          },
        });
      }
    },
    [getState, dispatch, isSearchActive, showToast],
  );

  const addItemsToKanbanColumn = useCallback(
    ({
      clips = [],
      boards = [],
      customFieldValueId,
    }: {
      clips?: Clip[];
      boards?: Board[];
      customFieldValueId?: string;
    }) => {
      const state = getState();
      const isKanbanView = isKanbanViewSelector(state);
      if (!isKanbanView) return;

      const kanbanColumnId = customFieldValueId || VisibleColumnType.unassignedCustomFieldValue;
      const currentColumnItems = kanbanColumnItemsSelector(state, kanbanColumnId);
      // if the ID is not a valid column in the current kanban
      if (!currentColumnItems) return;
      const convertedBoards: ClipAndBoardListItem[] = boards.map((board) => ({
        id: `${board.id}-board`,
        type: 'board',
        data: board,
      }));
      const convertedClips: ClipAndBoardListItem[] = clips.map((clip) => ({
        id: `${clip.id}-${clip.type === ClipType.nonMedia ? 'file' : 'asset'}`,
        type: clip.type === ClipType.nonMedia ? 'file' : 'asset',
        data: clip,
      }));

      dispatch(
        updateKanbanColumnWithNewDataAction({
          columnsToUpdate: {
            [kanbanColumnId]: [
              ...convertedBoards,
              ...convertedClips,
              ...currentColumnItems.filter((item) => item.dndType !== DndItemType.kanbanUpload).map((i) => i.apiData),
            ],
          },
        }),
      );

      const moveBeforeId = currentColumnItems.find((item) => item.dndType !== DndItemType.kanbanUpload)?.itemId;

      if (moveBeforeId) {
        const shortId = getShortIdFromPath(window.location.pathname);
        const boardId = getBoardIdFromPath(window.location.pathname);
        const idsToMove = [...boards.map((i) => i.id), ...clips.map((i) => i.assetId)];
        if (shortId || !boardId) {
          // TODO: Update public positions
          return;
        }

        const workspaceId = centralizedBoardWorkspaceIdSelector(getState());

        if (!workspaceId) {
          throw new Error('workspaceId is required');
        }

        return Items.updateItemPositions({
          workspaceId,
          idsToMove,
          betweenIds: { moveBeforeId },
          boardId,
          view: {
            id: currentViewIdSelector(state)!, // at this stage we should have this to cast
            type: ViewTypeName.kanban,
          },
        });
      }
    },
    [dispatch, getState],
  );
  const removeKanbanItems = useCallback(
    ({
      assetIds = [],
      boardIds = [],
    }: {
      assetIds?: Clip['assetId'][];
      clipIds?: Clip['id'][];
      boardIds?: Board['id'][];
    }) => {
      const state = getState();
      const isKanbanView = isKanbanViewSelector(state);
      if (!isKanbanView) return;
      assetIds.forEach((assetId) => {
        const { item } = findKanbanItemByAssetIdInColumnSelector(state, assetId);
        if (item) assetIds.push(item.itemId);
      });
      dispatch(removeKanbanItemsAction({ itemIds: [...assetIds, ...boardIds] }));
    },
    [dispatch, getState],
  );
  return { updateKanbanData, addItemsToKanbanColumn, removeKanbanItems };
};
