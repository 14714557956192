import { Modal, ModalCloseButton } from '@air/primitive-modal';
import Image from 'next/image';
import { memo } from 'react';

interface NoPlanUpgradePermissionModalProps {}

export const NoPlanUpgradePermissionModal = memo(({ onClose }: AirModalProps<NoPlanUpgradePermissionModalProps>) => {
  return (
    <Modal isOpen onDismiss={onClose}>
      <div className="flex flex-col items-center">
        <ModalCloseButton className="self-end" onClick={onClose} />
        <Image
          alt="Upgrade to Air Pro"
          className="mb-4"
          height={170}
          src="/assets/illustrations/upgrade-to-pro.svg"
          width={170}
        />
        <p className="mb-2 text-24 font-semibold text-blue-10">Upgrade to Air Pro</p>
        <p className="text-center text-16 text-grey-11">
          Contact the admin of your workspace to upgrade your plan to Pro
        </p>
      </div>
    </Modal>
  );
});

NoPlanUpgradePermissionModal.displayName = 'NoPlanUpgradePermissionModal';
