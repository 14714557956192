import { Board, BoardsListOptions, SortDirection, SubnavBoardSort } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';

import { usePrivateBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePrivateBoardsFetcher';
import { useSubnavSort } from '~/swr-hooks/subnav/useSubnavSort';

export const BOARD_SUBBOARDS = 'BOARD_SUBBOARDS';

export const getBoardSubBoardsKey = (boardId: Board['id'], sortField?: SubnavBoardSort) => {
  return [BOARD_SUBBOARDS, { boardId, sortField }];
};

export const useSubBoardsList = (boardId: string, canFetchData: boolean, params?: Partial<BoardsListOptions>) => {
  const { privateBoardsFetcher } = usePrivateBoardsFetcher();
  const { data: subnavSort } = useSubnavSort({ enabled: canFetchData });
  const sortField = subnavSort?.boardSort;

  const inifiteQuery = useInfiniteQuery(
    getBoardSubBoardsKey(boardId, sortField),
    ({ pageParam }) => {
      return privateBoardsFetcher({
        params: {
          isMemberOfBoard: 'ALL',
          view: boardId,
          numThumbnails: 0,
          includeAncestors: true,
          sortField: sortField ? { name: sortField.name, direction: sortField.direction } : undefined,
          ...params,
        },
        sortFieldName: sortField?.name || 'customSort',
        sortDirection: sortField?.direction || SortDirection.desc,
        // TODO: Just pass the pageParam as an actual param once everything using `privateBoardsFetcher` has been
        // moved to react-query
      })(pageParam ? `?cursor=${pageParam}` : '');
    },
    {
      enabled: !!boardId && canFetchData,
      getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    },
  );

  return {
    data: inifiteQuery.data?.pages.flatMap((d) => d.data) ?? [],
    hasMore: !!inifiteQuery.hasNextPage,
    loadNextPage: inifiteQuery.fetchNextPage,
    isLoadingMore: inifiteQuery.isFetchingNextPage,
    isInitialLoading: inifiteQuery.isLoading,
  };
};
