import { Members } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS';

export const getNotificationSettingsKey = ({
  workspaceId,
  accountId,
}: {
  workspaceId?: string;
  accountId?: string;
}) => [NOTIFICATION_SETTINGS, { workspaceId, accountId }];

export const useNotificationSettings = () => {
  const { data: account } = useAccountContext();
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getNotificationSettingsKey({
    workspaceId: currentWorkspace?.id,
    accountId: account?.id,
  });

  return useQuery(
    key,
    () => {
      invariant(account, 'Account must be defined');
      invariant(currentWorkspace, 'Current workspace must be defined');

      return Members.getNotificationSettings({ workspaceId: currentWorkspace.id });
    },
    {
      enabled: !!account && !!currentWorkspace,
    },
  );
};
