/**
 * @see https://docs.google.com/spreadsheets/d/1FNN_n0FiP49GCljsiqg1CMwv99KU6wWEloWiet3t_j8/edit#gid=668422112
 */

import { ScopedPermissions } from '@air/api';

export const canDownloadVersion = (versionContext: ScopedPermissions | undefined) =>
  !!versionContext?.asset.canViewOriginal;

export const canAddAssetToBoard = (assetContext: ScopedPermissions | undefined) => !!assetContext?.asset.canEdit;

export const canMoveAsset = (assetContext: ScopedPermissions | undefined) => !!assetContext?.asset.canEdit;

export const canCreateAsset = (targetContext: ScopedPermissions | undefined) => !!targetContext?.asset.canCreate;

export const canChangeVersionDetails = (clipContext: ScopedPermissions | undefined) => !!clipContext?.asset.canEdit;

export const canDeleteAsset = (parentContext: ScopedPermissions | undefined) => !!parentContext?.asset.canEdit;

export const canAddVersionToAsset = (assetContext: ScopedPermissions | undefined) => !!assetContext?.asset.canEdit;

export const canExportAsset = (assetContext: ScopedPermissions | undefined) => !!assetContext?.asset.canViewOriginal;

export const canSeeAssetVersions = (
  sourceContext: { asset: Pick<ScopedPermissions['asset'], 'canViewAllVersions'> } | undefined,
) => !!sourceContext?.asset.canViewAllVersions;

export const canExtractVersionFromAsset = ({
  assetContext,
  targetContext,
}: {
  assetContext: ScopedPermissions | undefined;
  targetContext: ScopedPermissions | undefined;
}) => !!assetContext?.asset.canEdit && !!targetContext?.asset.canCreate;

export const canMergeAsset = (assetContext: ScopedPermissions | undefined) => !!assetContext?.asset.canEdit;

export const canUpdateAssetDetails = (assetContext: ScopedPermissions | undefined) => !!assetContext?.asset.canEdit;

export const canManageVersionProcessingState = (clipContext: ScopedPermissions | undefined) =>
  !!clipContext?.asset.canEdit;

export const canSeeAssetCustomFields = (context: ScopedPermissions | undefined) => !!context?.customField.canView;

export const canChangeAssetCustomFields = (context: ScopedPermissions | undefined) =>
  !!context?.customField.canManageApplications;

/**
 * These discussion permissions should be broken out to be 1-1 with the permissions contract
 * however, without a refactor of the permissions, this would be a bit of work
 */
export const canViewAssetDiscussions = (context: ScopedPermissions | undefined) => !!context?.asset.canViewDiscussions;

export const canEditAssetDiscussions = (context: ScopedPermissions | undefined) =>
  !!context?.asset.canManageDiscussions;

export const canSeeClipTags = (clipContext: ScopedPermissions | undefined) => !!clipContext?.tag.canView;

export const canEditClipTags = (clipContext: ScopedPermissions | undefined) => !!clipContext?.tag.canManageApplications;

export const canRemoveClipTags = (clipContext: ScopedPermissions | undefined) =>
  !!clipContext?.tag.canManageApplications;

export const canSeeApplicableClipTags = (clipContext: ScopedPermissions | undefined) =>
  !!clipContext?.tag.canManageApplications;

export const canEditCapturedText = (clipContext: ScopedPermissions | undefined) => !!clipContext?.clip.canEdit;
