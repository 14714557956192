import { Clip, VisibleColumnType } from '@air/api/types';
import { createSelector } from '@reduxjs/toolkit';

import { uploadsForCurrentBoardSelector } from '~/store/centralizedBoard/selectors';
import { AirState } from '~/store/types';
import { DndItemType, DndSortableKanbanItemData } from '~/types/DndKit';

import { boardIdsBeingDeletedSelector } from '../tasks/selectors';
import { convertUploadToKanbanItemData } from './utils';

const baseKanbanManagerSelector = ({ kanbanManager }: AirState) => kanbanManager;

const passedIdSelector = (_: any, id: string) => id;

const kanbanColumnSelector = createSelector(
  [baseKanbanManagerSelector, boardIdsBeingDeletedSelector, passedIdSelector],
  (kanbanManager, excludedBoardIds, columnId) => {
    const column = kanbanManager.columns[columnId];
    if (column && excludedBoardIds.length > 0) {
      return {
        ...column,
        items: column.items.filter(
          (item) => item.dndType !== DndItemType.kanbanBoard || !excludedBoardIds.includes(item.itemId),
        ),
      };
    }
    return column;
  },
);

export const kanbanColumnItemsSelector = createSelector(
  [baseKanbanManagerSelector, uploadsForCurrentBoardSelector, boardIdsBeingDeletedSelector, passedIdSelector],
  (kanbanManager, uploads, excludedBoardIds, columnId) => {
    const items =
      kanbanManager.columns[columnId]?.items.filter(
        (item) => item.dndType !== DndItemType.kanbanBoard || !excludedBoardIds.includes(item.itemId),
      ) || [];

    if (columnId === VisibleColumnType.unassignedCustomFieldValue) {
      const dndUploads = uploads.map((upload) => convertUploadToKanbanItemData(upload));
      return [...dndUploads, ...items];
    }
    return items;
  },
);
export const kanbanColumnNeedsToLoadItemsSelector = createSelector([kanbanColumnSelector], (column) => !column?.items);

export const kanbanColumnSortableKeysSelector = createSelector(kanbanColumnItemsSelector, (kanbanItems) =>
  kanbanItems.map((item) => item.sortableKanbanItemKey),
);

export const findKanbanItemByClipIdInColumnSelector = createSelector(
  [baseKanbanManagerSelector, passedIdSelector],
  (kanbanManager, clipId) => {
    let item: DndSortableKanbanItemData | undefined;
    let columnId: string | undefined;
    for (const [columnKey, column] of Object.entries(kanbanManager.columns)) {
      item = column.items.find((item) => (item.apiData.data as Clip).id === clipId);
      if (item) {
        columnId = columnKey;
        break;
      }
    }
    const columnItems = columnId ? kanbanManager.columns[columnId].items : undefined;
    return {
      columnId,
      columnItems,
      item,
    };
  },
);

export const findKanbanItemByAssetIdInColumnSelector = createSelector(
  [baseKanbanManagerSelector, passedIdSelector],
  (kanbanManager, assetId) => {
    let item: DndSortableKanbanItemData | undefined;
    let columnId: string | undefined;
    for (const [columnKey, column] of Object.entries(kanbanManager.columns)) {
      item = column.items.find((item) => (item.apiData.data as Clip).assetId === assetId);
      if (item) {
        columnId = columnKey;
        break;
      }
    }
    const columnItems = columnId ? kanbanManager.columns[columnId].items : undefined;
    return {
      columnId,
      columnItems,
      item,
    };
  },
);

export const findKanbanItemInColumnSelector = createSelector(
  [baseKanbanManagerSelector, passedIdSelector],
  (kanbanManager, itemId) => {
    let item: DndSortableKanbanItemData | undefined;
    let columnId: string | undefined;
    for (const [columnKey, column] of Object.entries(kanbanManager.columns)) {
      item = column.items.find((item) => item.itemId === itemId);
      if (item) {
        columnId = columnKey;
        break;
      }
    }
    const columnItems = columnId ? kanbanManager.columns[columnId].items : undefined;
    return {
      columnId,
      columnItems,
      item,
    };
  },
);

export const kanbanColumnItemCountSelector = createSelector([kanbanColumnSelector], (column) => column?.items.length);

export const kanbanColumnUnsyncedItemsSelector = createSelector(
  [kanbanColumnSelector],
  (column) => column?.unsyncedItems,
);
