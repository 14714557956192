import { TriangleRight } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import classNames from 'classnames';
import { type ComponentPropsWithRef, ElementRef, forwardRef, memo, type ReactNode } from 'react';

export const treeItem = tailwindVariants({
  base: 'group/treeItem relative z-1 flex shrink-0 items-center gap-2 rounded-sm px-2 text-grey-11 outline outline-2 -outline-offset-2 outline-transparent hover:bg-grey-3 [.dragging_&]:opacity-50 [.hovering-drop_&]:bg-grey-3 [.hovering-drop_&]:outline-macaw-300 [.moving-item_&]:opacity-50',
  variants: {
    size: {
      small: 'h-8 text-14',
      medium: 'h-9 text-16',
    },
    state: {
      active: 'bg-grey-3',
      default: 'bg-transparent',
      selected: 'bg-blue-1 hover:bg-blue-2',
    },
  },
  defaultVariants: {
    size: 'medium',
    state: 'default',
  },
});

export const TREE_ITEM_ELEMENT_TYPE = 'div';

export type TreeItemVariants = VariantProps<typeof treeItem>;

export type TreeItemProps = Omit<ComponentPropsWithRef<typeof TREE_ITEM_ELEMENT_TYPE>, 'open' | 'prefix'> &
  TreeItemVariants & {
    defaultOpen?: boolean;
    onOpenChange?: (open: boolean) => void;
    open?: boolean;
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

const _TreeItem = forwardRef<ElementRef<typeof TREE_ITEM_ELEMENT_TYPE>, TreeItemProps>(
  (
    { children, className, defaultOpen, onOpenChange, open: openProp, prefix, size, state, suffix, ...restOfProps },
    forwardedRef,
  ) => {
    const [open = false, setOpen] = useControllableState({
      prop: openProp,
      defaultProp: defaultOpen,
      onChange: onOpenChange,
    });

    const label = open ? 'Collapse' : 'Expand';

    return (
      <div
        data-testid="TREE_ITEM"
        className={treeItem({ size, state, class: className })}
        ref={forwardedRef}
        {...restOfProps}
      >
        <Tooltip label={label}>
          <IconButton
            appearance="ghost"
            className="z-1 shrink-0"
            color="grey"
            label={label}
            onClick={() => setOpen((prevOpen) => !prevOpen)}
            size="extra-small"
            data-testid={open ? 'TREE_ITEM_COLLAPSE_BUTTON' : 'TREE_ITEM_EXPAND_BUTTON'}
          >
            <TriangleRight className={classNames('h-4 w-4 transition-transform', open ? 'rotate-90' : 'rotate-0')} />
          </IconButton>
        </Tooltip>

        {prefix && <div className="shrink-0">{prefix}</div>}

        {children}

        {suffix && <div className="shrink-0">{suffix}</div>}
      </div>
    );
  },
);

_TreeItem.displayName = '_TreeItem';

export const TreeItem = memo(_TreeItem);
