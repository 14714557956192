import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { SEARCH_TOTALS } from '~/constants/react-query-keys';

export const useUpdateSearchTotals = () => {
  const queryClient = useQueryClient();
  const removeFromSearchTotals = useCallback(
    (count: number) => {
      if (count > 0) {
        queryClient.setQueriesData<{ total: number } | undefined>([SEARCH_TOTALS], (data) => {
          if (data && data.total && data.total >= count) {
            return { total: data.total - count };
          }
          return data;
        });
      }
    },
    [queryClient],
  );

  return {
    removeFromSearchTotals,
  };
};
