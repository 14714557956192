import type { CreateTaskResponse, TaskType as RemoteTaskType } from '@air/api/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addRemoteTaskIdAction, markTaskAsFailedAction, setTaskAction } from '~/store/tasks/actions';
import type { GetTaskByType, GetTaskInStatus, TaskType as LocalTaskType } from '~/store/tasks/types';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { addTasksToLocalStorage, createLocalTaskId } from '~/utils/taskUtils/storage';

type CreateTaskParam<L extends LocalTaskType, R extends RemoteTaskType> = {
  task: Omit<GetTaskInStatus<GetTaskByType<L>, 'in-progress'>, 'localTaskId' | 'remoteTaskId' | 'status' | 'hidden'>;
  createTask: () => Promise<CreateTaskResponse<R>>;
};

type ZippingTaskParams = CreateTaskParam<'ZippingForDownload', 'ContentExporter'>;
type CTWTaskParams = CreateTaskParam<'CopyToWorkspace', 'ContentDuplicator'>;
type DuplicateBoardTaskParams = CreateTaskParam<'DuplicateBoard', 'ContentDuplicator'>;
type BoardDeletionTaskParams = CreateTaskParam<'BoardDeletion', 'ContentRemover'>;
type LibraryDeletionTaskParams = CreateTaskParam<'LibraryDeletion', 'ContentRemover'>;

// @ts-expect-error - BE is still working on this
type BoardRestorationTaskParams = CreateTaskParam<'BoardRestoration', 'Restoration'>;

// @ts-expect-error - BE is still working on this
type LibraryRestorationTaskParams = CreateTaskParam<'LibraryRestoration', 'Restoration'>;

export type CreateTaskParams =
  | ZippingTaskParams
  | CTWTaskParams
  | DuplicateBoardTaskParams
  | BoardRestorationTaskParams
  | LibraryRestorationTaskParams
  | BoardDeletionTaskParams
  | LibraryDeletionTaskParams;

export const useCreateTask = () => {
  const dispatch = useDispatch();

  const createTask = useCallback(
    async ({ task, createTask }: CreateTaskParams) => {
      /** We generate a temporary ID because we want to show something in the UI immediately */
      const localTaskId = createLocalTaskId();

      dispatch(
        setTaskAction({
          localTaskId,
          remoteTaskId: undefined,
          status: 'in-progress',
          hidden: false,
          ...task,
        }),
      );

      try {
        const remoteTask = await createTask();
        const remoteTaskId = remoteTask.id;

        addTasksToLocalStorage([
          {
            localTaskId,
            remoteTaskId,
            status: 'in-progress',
            hidden: false,
            ...task,
          },
        ]);

        dispatch(
          addRemoteTaskIdAction({
            localTaskId,
            remoteTaskId,
          }),
        );
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: `Failed to create task`,
          metadata: { task },
        });

        dispatch(markTaskAsFailedAction({ localTaskId }));
      }
    },
    [dispatch],
  );

  return {
    createTask,
  };
};
