import { useTrackActivatedIntercom } from '@air/analytics';
import { Check as CheckIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Box, Modal, Text } from '@air/zephyr';
import { useTheme } from 'styled-components';

import { Routes } from '~/constants/routes';

export const enterpriseOptions = [
  'Enriched usage reporting',
  'Advanced security and permissions',
  'Dedicated success manager',
  'Custom migrations',
  'Team training and onboarding',
];

export const EnterprisePlanModal = ({ onClose }: AirModalProps<{}>) => {
  const theme = useTheme();
  const { trackActivatedIntercom } = useTrackActivatedIntercom();

  const clickedContactSales = () => {
    trackActivatedIntercom({ location: 'enterprise-contact-sales' });
  };

  return (
    <Modal
      tx={{ width: 320 }}
      onDismiss={onClose}
      isAlertModal={false}
      modalLabel={
        <Text variant="text-ui-24" tx={{ textAlign: 'center', fontWeight: 'semibold', mt: 24, mb: 8, lineHeight: 1.5 }}>
          Enterprise plan
        </Text>
      }
    >
      <>
        <Box tx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text variant="text-ui-12" tx={{ color: 'pigeon600', textAlign: 'center' }}>
            For medium to large teams with 20+ members
          </Text>
          <Text variant="text-ui-16" tx={{ textAlign: 'center', fontWeight: 'medium', lineHeight: 1, my: 16 }}>
            Need to make changes?
          </Text>
          <Button appearance="outline" asChild color="blue" size="large" onClick={clickedContactSales}>
            <a href={Routes.enterprise.expansion} target="_blank">
              Contact Us
            </a>
          </Button>
        </Box>
        <Box tx={{ my: 40 }}>
          <Text tx={{ mb: 12, fontWeight: 'semibold' }} variant="text-ui-16">
            Your plan includes
          </Text>
          {enterpriseOptions.map((option, index) => (
            <Box tx={{ display: 'flex', '&:not(:last-of-type)': { mb: 12 } }} key={index}>
              <CheckIcon color={theme.colors.peacock500} width={16} style={{ marginTop: 2 }} />
              <Text tx={{ color: 'pigeon600', ml: 8 }} variant="text-ui-14">
                {option}
              </Text>
            </Box>
          ))}
        </Box>
      </>
    </Modal>
  );
};
