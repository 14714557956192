import { useSelector } from 'react-redux';

import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { centralizedBoardIdSelector } from '~/store/centralizedBoard/selectors';

export const useCurrentBoardPermissions = () => {
  const boardId = useSelector(centralizedBoardIdSelector);

  return useBoardPermissions({ boardId });
};
