export const isSafariBrowser =
  typeof window === 'undefined'
    ? false
    : window.navigator.userAgent.includes('Safari') && !window.navigator.userAgent.includes('Chrome');

export const isiOS = () => {
  if (typeof window === 'undefined') return false;

  const ua = window.navigator.userAgent;
  const isiOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  return isiOS;
};

export const isMacOS = () => {
  if (typeof window === 'undefined') return false;

  return window.navigator.userAgent.includes('Mac OS');
};

/**
 * @description Determines if the current browser is iosChrome
 * @see https://stackoverflow.com/a/13808053
 */
export const isChromeIos = () => (typeof window === 'undefined' ? false : !!window.navigator.userAgent.match('CriOS'));

// This is necessary for Cypress to mock methods, and must include all exported methods
module.exports = {
  isMacOS,
  isiOS,
  isChromeIos,
  isSafariBrowser,
};
